import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios, {AxiosError} from "axios";
import {UserModel} from "../../../domain/models/userModel";
import {CornyBakersEnv} from "../../../../../shared/environment/features/CornyBakersEnvironment";
import UserSliceModel from "./userSliceModel";
import {getCurrentUser, resetCurrentUserStorage} from "../currentUserSlice/currentUserSlice";
import i18next from "i18next";

axios.defaults.withCredentials = true

const API_BASE_URL = CornyBakersEnv.API_BASE_URL;

export const userLogin = createAsyncThunk(
  "user/login",
  async ({ name, pass } : { name: string, pass: string }, thunkAPI) => {
      const data = {
          name: name,
          pass: pass
      }
      const lang = i18next.language;
      const formatLang = lang === 'nl' ? '' : `/${lang}`;

      try {
          const response = await axios.post<UserModel>(API_BASE_URL + formatLang + '/user/login?_format=json', data, {
              headers: {
                  'Content-Type': 'application/json'
              }
          });

          thunkAPI.dispatch(getCurrentUser());

          return response.data;
      } catch (error: unknown) {
          if (error instanceof AxiosError) {
              return thunkAPI.rejectWithValue(error.message);
          }
          return thunkAPI.rejectWithValue('Something went wrong, please try again');
      }
  },
);

export const userLogout = createAsyncThunk(
  "user/logout",
  async (_, thunkAPI) => {

      const lang = i18next.language;
      const formatLang = lang === 'nl' ? '' : `/${lang}`;
      try {
          await axios.get(API_BASE_URL + formatLang + '/user/logout?_format=json', {
              headers: {
                  'Content-Type': 'application/json'
              }
          });

          resetCurrentUserStorage();

          return true;
      } catch (error: unknown) {
          if (error instanceof AxiosError) {
              return thunkAPI.rejectWithValue(error.message);
          }
          return thunkAPI.rejectWithValue('Something went wrong, please try again');
      }
  },
);

const initialState = {
    loading: false,
    error: null,
    user: {} as UserModel,
} as UserSliceModel;

const userSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder.addCase(userLogin.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(userLogin.fulfilled, (state, action: PayloadAction<UserModel>) => {
            state.loading = false;
            state.error = null;
            state.user = action.payload;
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        builder.addCase(userLogin.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(userLogout.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(userLogout.fulfilled, (state) => {
            state.loading = false;
            state.error = null;
            state.user = {} as UserModel;
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        builder.addCase(userLogout.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload;
        })
    }
});

export default userSlice.reducer;
