import React, {SyntheticEvent} from "react";
import {Form} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/useTypedSelector";
import {setItemsPerPage} from "../../../infrastructure/slices/productRangeSlice/productRangeSlice";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const MaxPageResults = () => {
    const {t} = useTranslation('common');
    const dispatch = useAppDispatch();
    const {itemsPerPage} = useAppSelector((state) => state.productRange);

    const navigation = useNavigate();

    const onChange = (event: SyntheticEvent) => {
        const target = event.target as HTMLInputElement;
        const newValue = target.value as unknown as number;

        const url = new URL(window.location.href);
        url.searchParams.set('items', newValue.toString());

        navigation(`${url.pathname}${url.search}`);

        dispatch(setItemsPerPage(newValue));
    }

    return (
        <Form.Group className={'d-flex justify-content-end align-items-center'}>
            <Form.Label className={'me-2 mb-0'}>{t('Products per page')} </Form.Label>
            <div>
                <Form.Select className={'items-per-page'} value={itemsPerPage} onChange={onChange}>
                    <option value={15}>15</option>
                    <option value={30}>30</option>
                    <option value={60}>60</option>
                    <option value={90}>90</option>
                </Form.Select>
            </div>
        </Form.Group>
    );
}
