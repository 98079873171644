import React, {useEffect} from "react";
import {PageTitle} from "../../../page/features/PageTitle";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useTypedSelector";
import {getProduct} from "../../infrastructure/slices/productSlice/productSlice";
import {Badge, Col, Row} from "react-bootstrap";
import {DefaultPage} from "../../../page/features/default";
import {ProductTabs} from "./product/productTabs";
import {defaultImages, ProductImage} from "./product/productImage";
import {AddToCart} from "../../../cart/modules/pages/cart/addToCart";
import {useParams} from "react-router-dom";
import {FavoriteBtn} from "../../../productFavorites/modules/commons/favoriteBtn";
import {useTranslation} from "react-i18next";
import {Loading} from "../../../general/features/loading";
import NotFoundPage from "../../../content/modules/pages/404Page";

export function ProductPage() {
    const dispatch = useAppDispatch();
    const {id} = useParams();
    const {t} = useTranslation('common');

    useEffect(() => {
        if (id) {
            dispatch(getProduct(id));
        }
    }, [id]);

    const {product, loading, loaded} = useAppSelector((state) => state.product);

    const formatNum = (number: string) => {
        return new Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'}).format(
            parseFloat(number),
        );
    }
    const replacements: { [index: string]: string } = {};
    if (!loading) {
        replacements[id as string] = product?.name ?? t('Product');
    }

    return (
        <DefaultPage className={"product-page mt-5"} replacements={replacements}>
            {(loaded && Object.keys(product).length === 0) ? <NotFoundPage/> : (
                <div className={'mh-500 p-rel'}>
                    <Row>
                        <Col
                            xs={{order: 1, span: 12}}
                            sm={{order: 0, span: 6}}
                            md={{order: 0, span: 6}}
                            className={'p-rel mh-500'}
                        >
                            {loading ? <><Loading/><ProductImage images={defaultImages}/></> :
                                <ProductImage images={product?.images ?? []}/>}
                        </Col>
                        <Col
                            xs={{order: 0, span: 12}}
                            sm={{order: 1, span: 6}}
                            md={{order: 1, span: 6}}
                        >
                            <PageTitle title={product?.name ?? ""}/>
                            <div className={'p-rel mh-150'}>
                                {loading ? <Loading/> : <>
                                    <div className={'product-price-badge'}>
                                        {(product?.scaled_prices?.length ?? 0) > 0 ? product.scaled_prices.map((item, key) =>
                                            <Badge key={key} bg={'primary'}>
                                                {formatNum(`${item.price ?? ''}`)}
                                                {item.quantity > 0 && <small>{item.quantity} st.</small>}
                                            </Badge>
                                        ) : (
                                            <Badge bg={'primary'}>{product.price ?? ''}</Badge>
                                        )}
                                    </div>
                                    <div className={'d-flex gap-10'}>
                                        <div className={"d-inline-flex"}>
                                            <AddToCart variationId={product?.variations?.drupal_internal__variation_id}
                                                       variant={'product'}/>
                                        </div>
                                        <FavoriteBtn variant={'product'} size={'25px'} productId={product.id}/>
                                    </div>
                                </>}
                            </div>
                        </Col>
                    </Row>

                    <Row className={"mt-5"}>
                        <Col className={'p-rel mh-50'}>
                            {loading ? <Loading/> : <ProductTabs product={product}/>}
                        </Col>
                    </Row>
                </div>
            )}
        </DefaultPage>
    );
}
