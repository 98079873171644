import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useTypedSelector";
import {userLogin} from "../../infrastructure/slices/userSlice/userSlice";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import i18next from "i18next";

export function LoginForm() {
    const {t} = useTranslation('common');
    const dispatch = useAppDispatch();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const {error} = useAppSelector(state => state.auth)

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const data = {
            name: username,
            pass: password
        }

        dispatch(userLogin(data))
    }

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Row className={'login-card p-2 py-4 mb-3'}>
                    <h4 className={'py-3'}>{t('Login clientportal')}</h4>
                    {error && (
                        <Alert key={'danger'} variant={'danger'}>
                            {t('Unrecognized username or password.')}
                        </Alert>
                    )}
                    <Form.Group className={'mb-3'} controlId={'loginForm.ControlUsername'}>
                        <Form.Label className={'m-0'}>{t('Username') || ''}</Form.Label>
                        <Form.Control
                            type={'text'}
                            required
                            defaultValue={username}
                            onChange={(event) => setUsername(event.target.value)}
                            placeholder={t('Fill in your Corny Bakers username')}
                        />
                    </Form.Group>
                    <Form.Group className={'mb-3'} controlId={'loginForm.ControlPassword'}>
                        <Form.Label className={'m-0'}>{t('Password') || ''}</Form.Label>
                        <Form.Control
                            type={'password'}
                            required
                            defaultValue={password}
                            onChange={(event) => setPassword(event.target.value)}
                            placeholder={t('Enter the password corresponding with your username')}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check
                            type={'checkbox'}
                            name={'rememberMe'}
                            id={'rememberMe'}
                            label={t('Remember me') || ''}
                        />
                    </Form.Group>
                </Row>
                <Row xs={'auto'} className={'align-items-center'}>
                    <Col className={'ps-0'}>
                        <Button variant={'cb-red'} type={'submit'} disabled={!(username && password)}>
                            {t('Login')}
                        </Button>
                    </Col>
                    <Col>
                        <Link to={`/${i18next.language}/user/password`}>{t('Forgot password?')}</Link>
                    </Col>
                </Row>
            </Form>
        </>
    );
}
