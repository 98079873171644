import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ProductSliceModel} from "./productSliceModel";
import axios, {AxiosError} from "axios";
import {ProductModel} from "../../../domain/models/productModel";
import {CornyBakersEnv} from "../../../../../shared/environment/features/CornyBakersEnvironment";
import i18next from "i18next";
import {formatProduct} from "../productRangeSlice/productRangeSlice";

const API_BASE_URL = CornyBakersEnv.API_BASE_URL;


export const getProduct = createAsyncThunk(
    "productSlice/getProduct",
    async (uuid: string, thunkAPI) => {

        const lang = i18next.language;
        const formatLang = lang === 'nl' ? '' : `/${lang}`;
        try {
            const response = await axios.get(API_BASE_URL + formatLang + '/jsonapi/commerce_product/default/' + uuid + '?jsonapi_include=1&include=cb_product,images,variations,is_favorite', {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const product = response.data;

            return formatProduct(product.data);
        } catch (error: unknown) {
            console.error(error);
            if (error instanceof AxiosError) {
                return thunkAPI.rejectWithValue(error.message);
            }
            return thunkAPI.rejectWithValue('Something went wrong, please try again');
        }
    });

const initialState = {
    loading: false,
    loaded: false,
    product: {} as ProductModel,
    cart: [],
} as ProductSliceModel;

const productSlice = createSlice({
    name: "productSlice",
    initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder.addCase(getProduct.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getProduct.fulfilled, (state, action) => {
            state.loading = false;
            state.product = action.payload as ProductModel;
            state.loaded = true;
        });
        builder.addCase(getProduct.rejected, (state) => {
            state.loading = false;
            state.loaded = true;
        });
    }
})

export default productSlice.reducer;
